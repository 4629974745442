import React from 'react';

import LogoImage from 'assets/images/logo.png';

import { StyledLogo } from './Logo.styles';

export const Logo = ({ ...props }) => {
  return (
    <StyledLogo {...props}>
      <img src={LogoImage} alt='SeedBox' />
    </StyledLogo>
  );
};
