import styled, { css } from 'styled-components';
import { rem } from 'styles';
import { Button } from 'components/_shared/Button';
import { ButtonIcon } from 'components/_shared/Button';

export const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: ${({ theme }) => theme.zIndexes[2]};
`;

export const CloseButton = styled(Button).attrs({
  variant: 'floating',
  icon: 'Cross'
})`
  ${({ theme }) => css`
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: ${({ theme }) => theme.zIndexes[2]};
    border: ${theme.borders.default(theme.fontSizes[1])};
    width: ${rem(20)};
    height: ${rem(20)};
    ${ButtonIcon} {
      width: ${rem(8)};
    }
  `}
`;
