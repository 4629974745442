import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet.vectorgrid';
import { last } from 'lodash';
import {
  getSelectedResults,
  getLayerOpacity,
  useAreasActions,
  selectUser,
  selectAreasList
} from 'state';
import { API } from 'api';
import {
  DEFAULT_SHAPE,
  FILL_OPACITY,
  MODAL_TYPE,
  SELECTED_SHAPE_OPTIONS,
  AOI_TYPE,
  SIDEBAR_MODE,
  CO2
} from '_constants';
import { areasEvents } from '_events';
import { getPolygonPositions, getNewAreaNumber, fetchRequestResult } from 'utils';

export const useMapRequests = (selectedArea, map) => {
  const { addNewArea, deleteSelectedResult, setSidebarMode, setChartCoords } =
    useAreasActions();
  const results = useSelector(getSelectedResults);
  const opacity = useSelector(getLayerOpacity);
  const currentUser = useSelector(selectUser);
  const initialAreas = useSelector(selectAreasList);
  const [renderedLayers, setRenderedLayers] = useState([]);
  const [prevRenderedLayers, setPrevRenderedLayers] = useState([]);

  const createField = useCallback(
    coords => ({
      user: currentUser.pk,
      name: `New field ${getNewAreaNumber(initialAreas, AOI_TYPE.FIELD)}`,
      polygon: coords,
      type: 2
    }),
    [currentUser, initialAreas]
  );

  const addNewField = useCallback(
    polygon => {
      setSidebarMode(SIDEBAR_MODE.REQUESTS);
      addNewArea(polygon);
      deleteSelectedResult();
      areasEvents.toggleModal(true, {
        type: MODAL_TYPE.SAVE_FIELD,
        prevArea: selectedArea.id
      });
    },
    [deleteSelectedResult, selectedArea, setSidebarMode, addNewArea]
  );

  useEffect(() => {
    const selectedResults = [];
    results.forEach(id => {
      const result = selectedArea?.results.find(result => result.id === id);
      if (result) {
        selectedResults.push(result);
      }
    });

    if (selectedResults.length < renderedLayers.length) {
      const deletedLayers = [];
      const filteredLayers = renderedLayers.filter(rendered => {
        if (selectedResults.some(l => l.id === rendered.id)) {
          return rendered;
        } else {
          deletedLayers.push(rendered);
        }
      });
      setRenderedLayers(filteredLayers);
      deletedLayers.forEach(l => map.removeLayer(l.layer));
    }

    const addLayerInMap = (layer, selectedLayer) => {
      if (!renderedLayers.some(l => l.id === selectedLayer.id)) {
        map.addLayer(layer);
        setRenderedLayers([...renderedLayers, { id: selectedLayer.id, layer }]);
      }
    };

    selectedResults.forEach(async selectedLayer => {
      let selectedLeafletLayer = null;
      let layer = null;
      let layerStyle = null;
      if (selectedLayer.layer_type === 'GEOJSON') {
        layer = L.geoJSON(undefined, {
          style: feature => {
            layerStyle = feature.properties.style;
            return feature.properties.style;
          },
          onEachFeature: (feature, layer) => {
            layer.addEventListener('click', e => {
              const isSetDefaultStyle =
                selectedLeafletLayer != null &&
                selectedLeafletLayer._leaflet_id !== e.target._leaflet_id;
              const chartData = {
                x: [feature.properties.info],
                y: [feature.properties[CO2]]
              };
              // check is intersects results polygons and build chart
              const otherResults = selectedArea.results.filter(
                ({ id }) => id !== selectedLayer.id
              );
              otherResults.forEach(async item => {
                const resp = await fetchRequestResult(item.rel_url);
                resp.features.forEach((item, i) => {
                  const isIntersects = L.polygon(feature.geometry.coordinates)
                    .getBounds()
                    .intersects(L.polygon(item.geometry.coordinates).getBounds());
                  if (isIntersects) {
                    chartData.x = [...chartData.x, item.properties.info + (i + 1)];
                    chartData.y = [...chartData.y, item.properties[CO2]];
                  }
                });
                setChartCoords(chartData);
              });
              // change result area color on click
              e.target.setStyle(SELECTED_SHAPE_OPTIONS);
              if (isSetDefaultStyle) {
                selectedLeafletLayer.setStyle(layerStyle ?? DEFAULT_SHAPE);
              }
              selectedLeafletLayer = layer;
            });

            if (feature.properties.label) {
              layer.bindPopup(feature.properties.label);
            } else if (feature.properties.info === 'client data') {
              layer.setStyle({ weight: 15 });
            }
          }
        });
        const resp = await fetchRequestResult(selectedLayer.rel_url);
        layer.addData(resp);
        addLayerInMap(layer, selectedLayer);
      } else if (selectedLayer.layer_type === 'MVT') {
        layer = L.vectorGrid.protobuf(selectedLayer.rel_url, {
          rendererFactory: L.canvas.tile,
          maxZoom: 17,
          vectorTileLayerStyles: {
            default: properties => {
              if (typeof properties.style === 'string') {
                properties.style = JSON.parse(properties.style);
              }
              if (typeof properties.data === 'string') {
                properties.data = JSON.parse(properties.data);
              }
              if (typeof properties.layout === 'string') {
                properties.layout = JSON.parse(properties.layout);
              }
              if (properties.style === undefined) {
                properties.style = {};
              }
              if (properties.style.fill === undefined) {
                properties.style.fill = true;
              }
              return properties.style;
            }
          },
          interactive: true
        });
        layer.addEventListener('click', async e => {
          const coords = { lat: e.latlng.lng, lng: e.latlng.lat };
          const resp = await API.areas.getField(selectedLayer.id, coords);
          addNewField(createField(resp.data.polygon));
        });
        addLayerInMap(layer, selectedLayer);
      } else if (selectedLayer.layer_type === 'XYZ') {
        layer = L.tileLayer(selectedLayer.rel_url, {
          minZoom: 10,
          maxZoom: 17
        });
        addLayerInMap(layer, selectedLayer);
      }

      if (prevRenderedLayers.length === renderedLayers.length) {
        return;
      }
      setPrevRenderedLayers(renderedLayers);
      const lastId = last(renderedLayers)?.id;
      if (lastId) {
        const layer = selectedResults.find(l => l.id === lastId);
        if (layer) {
          map.fitBounds(
            getPolygonPositions({ polygon: layer.bounding_polygon }).coordinates[0]
          );
        }
      }
    });
  }, [
    map,
    selectedArea,
    results,
    renderedLayers,
    prevRenderedLayers,
    createField,
    addNewField,
    setChartCoords
  ]);

  useEffect(() => {
    const selectedLayer = last(renderedLayers);
    if (selectedLayer) {
      const fillOpacity = opacity / (1 / FILL_OPACITY);
      if (selectedLayer.layer.setStyle) {
        selectedLayer.layer.setStyle({ opacity, fillOpacity });
      } else {
        selectedLayer.layer.setOpacity(opacity);
      }
    }
  }, [renderedLayers, opacity]);
};
