import React from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import { getChartCoords } from 'state';
import { CO2 } from '_constants';
import { useAreasActions } from 'state';
import { Wrapper, CloseButton } from './Chart.styles';

export const Chart = () => {
  const coords = useSelector(getChartCoords);
  const { setChartCoords } = useAreasActions();

  if (coords)
    return (
      <Wrapper>
        <CloseButton onClick={() => setChartCoords()} />
        <Plot
          data={[{ type: 'bar', x: coords.x, y: coords.y }]}
          layout={{
            width: 320,
            height: 240,
            title: CO2
          }}
        />
      </Wrapper>
    );
  return null;
};
